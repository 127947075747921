import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faSquare, faTrash, faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import Slider from '@mui/material/Slider';
import styled from 'styled-components';
import { createFilters, 
        fetchFilters, 
        deleteFilters, 
        updateFilters } from '../actions/restaurantActions';
import PlusMinusInputsToFilters from '../components/PlusMinusInputsToFilters';
import LoaderComponent from '../components/LoaderComponent'

const StyledModalBody = styled(Modal.Body)`
  height: 400px; // Set a static height for the modal body
  overflow-y: auto; // Make the modal body scrollable if the content overflows
  padding: 20px; // Add some padding for aesthetics
`;

const FilterItemContainer = styled.div`
  display: flex; // This will line up child elements in a row
  align-items: center; // This will vertically center align the child elements
  justify-content: space-between; // This will add space between the child elements
  padding: 10px; // Add some padding for aesthetics
  margin-bottom: 10px; // Add margin to separate each filter item
  background: #f5f5f5; // A light background to distinguish each filter item
  border-radius: 5px; // Rounded corners for a modern look
`;

const FilterItemContainerTime = styled.div`
  display: flex; // This will line up child elements in a row
  flex-wrap: wrap; // This will wrap child elements to the next line if they overflow
  align-items: center; // This will vertically center align the child elements
  gap:5px;
`;

const EditableField = styled.input`
  width: 100px;
  margin-right: 10px; // Adds some space between the input field and the next element
  padding: 5px; // Padding inside the input field for aesthetics
  border: 1px solid #ccc; // A light border for the input field
  border-radius: 4px; // Rounded corners for the input field
`;

const TimeRangeSlider = styled(Slider)`
  // Additional styles for the slider if necessary
`;

const FiltersManagement = ({ restaurantId }) => {
  const dispatch = useDispatch();

const getFilters = useSelector((state) => state.filtersModels);
const {   // creating filters
          isLoadingCreateFilters,
          createdFilters,
          createdFiltersError,
          // fetching filters
          startFetchingFilters,
          fetchedFilters,
          fetchedFiltersError,
          // Deleting filters
          isLoadingDeletingFilters,
          deletingFilters,
          deletingFiltersError,
          // updating filters
          isLoadingUpdatingFilters,
          updatingFilters,
          updatingFiltersError,

      } = getFilters;

  const [filters, setFilters] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [toDeleteIds, setToDeleteIds] = useState([]);

  const [newFilters, setNewFilters] = useState([]);
  const [editableFilters, setEditableFilters] = useState([]);
  const [hasOverlap, setHasOverlap] = useState(false);

  const generateIntervalOptions = () => {
    const intervalOptions = [];
    for (let i = 0; i < 24; i++) {  // Loop through hours from 0 to 23
        for (let j = 0; j < 60; j += 30) {  // Loop through minutes, stepping by 30 (i.e., 0 and 30)
            // Format the hour and minute values to have two digits
            const hour = i.toString().padStart(2, '0');
            const minute = j.toString().padStart(2, '0');
            const time = `${hour}:${minute}`;

            intervalOptions.push(time);
        }
    }

    // Add the edge case for 24:00 manually
    intervalOptions.push('24:00');

    return intervalOptions;
};

// Usage:
const [intervalOptions, setIntervalOptions] = useState(generateIntervalOptions());


  // Sync local state with Redux store
  useEffect(() => {
    if (fetchedFilters) {
      setEditableFilters(fetchedFilters);
    }
  }, [fetchedFilters]);

  useEffect(() => {
    dispatch(fetchFilters(restaurantId))
    // Fetch existing filters for the restaurant and set them in state
    console.log('isOpen: ', isOpen)
  }, [isOpen]);

  // Function to add a new filter to the state
  const handleAddNewFilter = () => {
    const newFilter = {
      id: Date.now(), // Temporary unique id for React keys
      restaurant_id: restaurantId,
      name: '',
      start_time: '12:00',
      end_time: '20:00'
    };
    setNewFilters([...newFilters, newFilter]);
  };

  // Function to update filter properties
  const handleUpdateNewFilter = (tempId, field, value) => {
    const updatedFilters = newFilters.map(filter => {
      if (filter.id === tempId) {
        return { ...filter, [field]: value };
      }
      return filter;
    });
    setNewFilters(updatedFilters);
  };

  // Function to update filter properties
  const handleUpdateEditableFilter = (filter_id, field, value) => {
    const editedFilters = editableFilters.map(filter => {
      if (filter.id === filter_id) {
        console.log('is it true')
        return { ...filter, [field]: value };
      }
      return filter;
    });
    console.log('editedFilters:  ', editedFilters)
    setEditableFilters(editedFilters);
  };

  // Function to delete a filter from the state
  const handleDeleteNewFilter = (tempId) => {
    setNewFilters(newFilters.filter(filter => filter.id !== tempId));
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const addToDeleteIds = (id) => {
    setToDeleteIds([...toDeleteIds, id]);
    // Remove the object with the given id from the editableFilters array
    const updatedEditableFilters = editableFilters.filter(filter => filter.id !== id);
    setEditableFilters(updatedEditableFilters);
  };

  const handleSave = () => {
    
    // Proceed with the dispatching actions for creating, updating, and deleting filters
    dispatch(createFilters(restaurantId, newFilters));
    dispatch(updateFilters(restaurantId, editableFilters));
    dispatch(deleteFilters(restaurantId, toDeleteIds));
  
    setEditMode(false);
  };

  useEffect(() => {
    console.log('toDeleteIds: ', toDeleteIds)
    console.log('editableFilters: ', editableFilters)
    console.log('newFilters: ', newFilters)
  },[toDeleteIds, editableFilters, newFilters])

  const handleAddFilter = () => {
    // Add a new filter to the `filters` state with temporary unique ID
  };

  const handleDeleteFilter = (id) => {
    // Mark a filter for deletion by adding its ID to `toDeleteIds`
  };

  return (
    <>
    <LoaderComponent activate={startFetchingFilters}/>
        <FontAwesomeIcon size='xl' icon={faGear} onClick={() => setIsOpen(!isOpen)} style={{cursor:'pointer' , marginLeft: '5px', marginRight: '5px', paddingTop:'3px'}}  />
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Edição de filtros</Modal.Title>
            </Modal.Header>
            <StyledModalBody>

                {editMode && newFilters.map((filter) => (
                  <FilterItemContainer key={filter.id}>
                    <EditableField
                      value={filter.name}
                      onChange={(e) => handleUpdateNewFilter(filter.id, 'name', e.target.value)}
                      disabled={!editMode}
                    />
                    <FilterItemContainerTime>
                      <div>
                        <PlusMinusInputsToFilters
                          handleOptionsChange={(e) => handleUpdateNewFilter(filter.id, 'start_time', e.target.value)}
                          options={intervalOptions}
                          currentOption={filter.start_time}
                        />
                      </div>
                        
                        <div>
                        <PlusMinusInputsToFilters
                          handleOptionsChange={(e) => handleUpdateNewFilter(filter.id, 'end_time', e.target.value)}
                          options={intervalOptions}
                          currentOption={filter.end_time}
                        />

                        </div>
                        

                    </FilterItemContainerTime>
                    
                    {editMode && (
                      <FontAwesomeIcon size={'xl'}icon={faSquareXmark} onClick={() => handleDeleteNewFilter(filter.id)} style={{cursor:'pointer', color:'red'}}/>
                    )}
                  </FilterItemContainer>
                ))}
                {editableFilters && editableFilters.map((filter) => (
                    <FilterItemContainer key={filter.id}>
                        <EditableField 
                          disabled={!editMode} 
                          value={filter.name} 
                          onChange={(e) => handleUpdateEditableFilter(filter.id, 'name', e.target.value)}
                        />
                        <FilterItemContainerTime>
                      <div>
                        <PlusMinusInputsToFilters
                          handleOptionsChange={(e) => handleUpdateEditableFilter(filter.id, 'start_time', e.target.value)}
                          options={intervalOptions}
                          currentOption={filter.start_time}
                        />
                      </div>
                        
                        <div>
                        <PlusMinusInputsToFilters
                          handleOptionsChange={(e) => handleUpdateEditableFilter(filter.id, 'end_time', e.target.value)}
                          options={intervalOptions}
                          currentOption={filter.end_time}
                        />

                        </div>
                        

                    </FilterItemContainerTime>
                    {editMode && (
                      <FontAwesomeIcon size={'xl'}icon={faSquareXmark} onClick={() => addToDeleteIds(filter.id)} style={{cursor:'pointer', color:'red'}}/>
                    )}
                    </FilterItemContainer>
                ))
            }
            
            </StyledModalBody>
            <Modal.Footer>
              {!editMode && <button className='btn btn-primary' onClick={handleEdit}>Editar</button>}
              {editMode && (
                <>
                  <button 
                    className='btn btn-primary' 
                    onClick={handleSave} 
                  >
                    Guardar
                  </button>
                  <button className='btn btn-primary' onClick={handleAddNewFilter}>Adicionar +</button>
                </>
                
              )}
            </Modal.Footer>
        </Modal>
    </>
);

};

export default FiltersManagement;
