import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock} from '@fortawesome/free-solid-svg-icons'

const OptionsBox = styled.div`
  display: flex;
  justify-content:center;
  flex-wrap:wrap;
  border:1px solid gray;
  border-radius:4px;
  overflow:hidden;
  height:150px;
  align-items:center;
  width: 85%;
  background-color: white;
`;

const OptionsWrapper = styled.div`
  display: flex;
  justify-content:center;
  flex-wrap:wrap;
  border-radius:4px;
  overflow:auto;
  height:100%;
  align-items:center;
  width: 100%;
  /* This part styles the scrollbar track (the background) */
  &::-webkit-scrollbar {
    width: 8px; /* You can change the width of the scrollbar */
  }

  /* This part styles the scrollbar thumb (the moving part) */
  &::-webkit-scrollbar-thumb {
    background: #a0a0a0; /* Here you can change the color of the scrollbar */
    border-radius: 4px; /* Match the border-radius to OptionsWrapper for consistency */
    &:hover {
      background: #909090; /* Slightly darker color on hover */
    }
  }

  /* This part styles the scrollbar corner (where horizontal and vertical scrollbars meet) */
  &::-webkit-scrollbar-corner {
    background-color: transparent; /* Makes the scrollbar corner transparent */
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #a0a0a0 transparent; /* Thumb and track color */
`;

const Options = styled.div`
  cursor:pointer;
  display: flex;
  justify-content:center;
  flex-wrap:wrap;
  border-radius:4px;
  overflow:auto;
  align-items:center;
  width: 100%;
  margin:5px;
  height:30px;
  background-color: ${props => (props.isSelected? '#41ab8b' 
                      : props.isFull ? '#c46127'
                      :'white')};
  color:${props => (props.isSelected || props.isFull? 'white'
                    : 'black')};
    &:hover {
        background-color: #a0decb;
        }
`;

const TimeOption = React.forwardRef(({ isFull, isSelected, onMouseDown, children }, ref) => {
    const [isHovered, setIsHovered] = useState(false);
  
    const optionStyle = getOptionStyle(isSelected, isFull, isHovered);
  
    return (
      <div
        ref={ref}
        style={optionStyle}
        onMouseDown={onMouseDown}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {children}
      </div>
    );
  });
  
  const getOptionStyle = (isSelected, isFull, isHovered) => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    borderRadius: '4px',
    overflow: 'auto',
    alignItems: 'center',
    width: '100%',
    margin: '5px',
    height: '30px',
    backgroundColor: isSelected ? '#41ab8b' : isFull ? '#c46127' : isHovered ? '#a0decb' : '#daede6',
    color: isSelected || isFull ? 'white' : 'black',
  });
  

function ChooseTime({options, onTimeChange, time, setTime, 
                    deactivateBaby, handleBabyActive, 
                    enableFullFlag = false,
                    currentZone, editingStartFalse,
                    reservation, update, true_create_false_edit, from_restaurant}) {
    const [selectedTime, setSelectedTime] = useState(time || null);
    const [showPopup, setShowPopup] = useState(false);
    const [wasActive, setWasActive] = useState(false);
    const [allOptionsFull, setAllOptionsFull] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentOptions, setCurrentOptions] = useState('');
    const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);
    const optionRefs = useRef([]);
    const selectedTimeRef = useRef(null);
    const [key, setKey] = useState(0);

    const [ignoreFirstTime, setIgnoreFirstTime] = useState(true);
    
    useEffect(() => {
        console.log('time: ', time)
        if (selectedTime === null) {
            setSelectedTime(time);
        };
    },[time]);

    useEffect(() => {
        setIgnoreFirstTime(true)
    },[reservation]);
    
    useEffect(() => {
        if ((!reservation || !ignoreFirstTime) && !(true_create_false_edit === false && from_restaurant === true)) {
            setTime('');
            setSelectedTime(null);
        } else {
            setSelectedTime(time);
        }
        setIgnoreFirstTime(false);
    },[update])

    const handleTimeClick = (event, time, originalTime,index) => {
        event.preventDefault();
        if (enableFullFlag && time.endsWith('/f')) {
        // Ignore clicks on 'full' options
        return;
        }
        setSelectedTime(time);
        setShowPopup(false);
        setTime(time);
        onTimeChange(time);
        if (wasActive == true) {
        handleBabyActive(true);
        } else {
        handleBabyActive(false);
        }
        setSelectedTimeIndex(index);
    };

    const getValueFromOptions = (options, key) => {
        if (options && key && key in options) {
            return options[key];
        }
        return null; // or a default value
    };
    useEffect(() => {
        if (!options || !currentZone) return;
        let timesInCurrentZone = getValueFromOptions(options,currentZone);
        setCurrentOptions(timesInCurrentZone);
    }, [options, currentZone]);
    
    useEffect(() => {
        if (currentOptions) {
            setKey(prevKey => prevKey + 1);
        }
    },[currentOptions])

    
    useEffect(() => {
        let findNewTime = false;
        if (time && Array.isArray(currentOptions)) {
        for (let option of currentOptions) {
            if (option.slice(2,7) === time && option.endsWith('/f')) {
            findNewTime = true;
            }
        }
        }
        if (findNewTime) {
        for (let option of currentOptions) {
            if (!option.endsWith('/f')) {
                    // setTime(option.slice(2));
                    // setSelectedTime(option.slice(2));
                break;
            }
            }
        }
    },[time, currentOptions])

    const togglePopup = (event) => {
        event.preventDefault();
        setShowPopup(!showPopup);
        if (deactivateBaby == true) {
        handleBabyActive(false);
        setWasActive(true);
        } else {
        handleBabyActive(false);
        }
        // Scroll to the selected item when the popup opens
        
    };
    if (ignoreFirstTime) {
        setTimeout(() => {
            if (selectedTime !== null) {
            selectedTimeRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        }, 200); // Increase the delay to 500ms, you can tweak this value
    }
    

    useEffect(() => {
        if (Array.isArray(currentOptions) && currentOptions && currentOptions.length) { 
        if (enableFullFlag) {
            setAllOptionsFull(currentOptions.every(opt => opt.endsWith('/f')));
        } else {
            setAllOptionsFull(false);
        }
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [currentOptions, enableFullFlag]);

    useEffect(() => {
        if (showPopup) {
        selectedTimeRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [showPopup, selectedTime, selectedTimeRef]);
                    
    useEffect(() => {
        if (selectedTimeRef.current) {
          selectedTimeRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, [currentOptions, time, ignoreFirstTime, reservation, selectedTimeRef, currentZone]); 

  return (
    <div style={{display:'flex'}}>
        {(Array.isArray(currentOptions) && currentOptions.length <= 0) || (currentZone === '') ?
        <></>
        :
        <>
            <FontAwesomeIcon size='xl' icon={faClock} style={{ marginRight: '5px', paddingTop:'5px'}}/>
            <OptionsBox key={key}>
                <OptionsWrapper onClick={(event) => event.stopPropagation()}>
                    {Array.isArray(currentOptions) && currentOptions.map((originalTime, index) => {
                            let isFull = enableFullFlag && originalTime.endsWith('/f');
                            let displayTime = isFull ? originalTime.slice(0, -2) + " | Lotado" : originalTime;
                            let timeForHandler = originalTime;
                            let isSelected = selectedTime === timeForHandler;
                            return (
                            <TimeOption
                                ref={isSelected ? selectedTimeRef : null}
                                key={index}
                                isFull={isFull}
                                isSelected={isSelected}
                                onMouseDown={(event) => handleTimeClick(event, timeForHandler, originalTime,index)}
                            >
                                {displayTime}
                            </TimeOption>
                            );
                        })}

                </OptionsWrapper>
            </OptionsBox>
        </>
        }
    </div>
    
  )
}

export default ChooseTime