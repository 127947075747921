import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
}

export const restaurantListSlice = createSlice({
  name: 'restaurantList',
  initialState,
  reducers: {
    startListingRestaurants: (state) => {
        state.isLoading = true;
    },
    listTheRestaurants: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
        state.restaurants = action.payload;
        state.isLoading = false;
    },
    errorlistRestaurants: (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      },
  },
})

export const restaurantDetailsSlice = createSlice({
  name: 'restaurantDetails',
  initialState,
  reducers: {
    startGetRestaurant: (state) => {
        state.isLoading = true;
        state.restaurants = [];
    },
    getRestaurant: (state, action) => {
        state.restaurants = action.payload;
        state.isLoading = false;
    },
    errorGetRestaurants: (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.restaurants = false;
      },
    RestaurantDetailsReset: (state) => {
      return {}
      },
  },
})


export const reservationsFromRestaurantSlice = createSlice({
  name: 'reservationsFromRestaurant',
  initialState,
  reducers: {
    getReservationsFromRestaurantRequest: (state) => {
        state.isLoading = true;
    },
    getReservationsFromRestaurantSucess: (state, action) => {
        state.reservations = action.payload;
        state.isLoading = false;
    },
    getReservationsFromRestaurantNottingToChange: (state) => {
      state.isLoading = false;
      state.AllReservationsIsLoading = false;
    },
    getReservationsFromRestaurantError: (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.reservations = false;
    },
    // all_reservations
    getAllReservationsFromRestaurantRequest: (state) => {
      state.AllReservationsIsLoading = true;
    },
    getAllReservationsFromRestaurantSucess: (state, action) => {
        state.allReservations = action.payload;
        state.AllReservationsIsLoading = false;
    },
    getAllReservationsFromRestaurantError: (state) => {
      state.AllReservationsIsLoading = false;
      state.allReservations = false;
    },
  },
})


export const reservationAcceptSlice = createSlice({
  name: 'reservationAccept',
  initialState,
  reducers: {
    reservationAcceptRequest: (state) => {
        state.isLoadingAccept = true;
    },
    reservationAcceptSucess: (state, action) => {
        state.messageAccept = action.payload;
        state.isLoadingAccept = false;
    },
    reservationAcceptError: (state, action) => {
        state.error = action.payload;
        state.isLoadingAccept = false;
        state.reservations = false;
      },
  },
})


export const availabeTablesToChangeSlice = createSlice({
  name: 'availabeTablesToChange',
  initialState,
  reducers: {
    availabeTablesToChangeRequest: (state) => {
        state.isLoadingTables = true;
    },
    availabeTablesToChangeSucess: (state, action) => {
        state.tables = action.payload;
        state.isLoadingTables = false;
    },
    availabeTablesToChangeError: (state, action) => {
        state.errorTables = action.payload;
        state.isLoadingTables = false;
        state.tables = false;
      },
  },
})

export const changeTablesSlice = createSlice({
  name: 'changeTables',
  initialState,
  reducers: {
    changeTableRequest: (state) => {
        state.isLoadingChangeTables = true;
    },
    changeTableSucess: (state, action) => {
        state.tablesChanged = action.payload;
        state.isLoadingChangeTables = false;
    },
    changeTableError: (state, action) => {
        state.errorChangedTables = action.payload;
        state.isLoadingChangeTables = false;
        state.tablesChanged  = false;
      },
  },
})

export const restaurantOpenDaysOfTheWeekSlice = createSlice({
  name: 'restaurantOpenDaysOfTheWeek',
  initialState,
  reducers: {
    startFetchingOpenDays: (state) => {
      state.isLoadingOpenDays = true;
    },
    fetchOpenDaysSuccess: (state, action) => {
      state.openDays = action.payload;
      state.isLoadingOpenDays = false;
    },
    fetchOpenDaysError: (state, action) => {
      state.error = action.payload;
      state.isLoadingOpenDays = false;
    },
  },
});

export const estimatedTimeCustomersSpendSlice = createSlice({
  name: 'estimatedTimeCustomersSpend',
  initialState,
  reducers: {
    startFetchingEstimatedTime: (state) => {
      state.isLoading = true;
    },
    fetchEstimatedTimeSuccess: (state, action) => {
      state.estimatedTime = action.payload;
      state.isLoading = false;
    },
    fetchEstimatedTimeError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const closedExceptionsSlice = createSlice({
  name: 'closedExceptions',
  initialState,
  reducers: {
    startFetchingClosedExceptions: (state) => {
      state.isLoading = true;
      state.exceptions = [];
    },
    fetchClosedExceptionsSuccess: (state, action) => {
      state.exceptions = action.payload;
      state.isLoading = false;
    },
    fetchClosedExceptionsError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const placeOfTableSlice = createSlice({
  name: 'placeOfTable',
  initialState,
  reducers: {
    startFetchingPlaceOfTable: (state) => {
      state.isLoading = true;
    },
    fetchPlaceOfTableSuccess: (state, action) => {
      state.zones = action.payload;
      state.isLoading = false;
    },
    fetchPlaceOfTableError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    startFetchingTables: (state) => {
      state.isLoading = true;
    },
    fetchTablesSuccess: (state, action) => {
      state.tables = action.payload;
      state.isLoading = false;
    },
    fetchTablesError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const fetchHoursEachTimeSlice = createSlice({
  name: 'hoursEach',
  initialState,
  reducers: {
    startFetchingHoursEach: (state) => {
      state.isLoading = true;
      state.hoursEach = [];
    },
    fetchHoursEachSuccess: (state, action) => {
      state.hoursEach = action.payload;
      state.isLoading = false;
    },
    fetchHoursEachError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

//   This reducer is to fetch the connect tables configuration
//   in case they have special tables that when connected makes up for more seats

export const fetchConnectTablesSlice = createSlice({
  name: 'connectTablesSlice',
  initialState,
  reducers: {
    startFetchingConnectTables: (state) => {
      state.isLoading = true;
    },
    fetchcanConnectTablesSuccess: (state, action) => {
      state.connectTablesConfig = action.payload;
      state.isLoading = false;
    },
    fetchConnectTablesError: (state, action) => {
      state.errorConnectTables = action.payload;
      state.isLoading = false;
    },
  },
});


export const getReservationSumsSlice = createSlice({
  name: 'confirmationSlice',
  initialState,
  reducers: {
    startGetReservationSums: (state) => {
      state.isLoadingReservationSums = true;
    },
    getReservationSumsSuccess: (state, action) => {
      state.reservationSums = action.payload;
      state.isLoadingReservationSums = false;
    },
    getReservationSumsError: (state, action) => {
      state.errorReservationSums = action.payload;
      state.isLoadingReservationSums = false;
    },
  },
});

export const create_confirmationSlice = createSlice({
  name: 'confirmationSlice',
  initialState,
  reducers: {
    startCreateConfirmation: (state) => {
      state.isLoadingCreate = true;
    },
    createSuccess: (state, action) => {
      state.createLoad = action.payload;
      state.isLoadingCreate = false;
    },
    createError: (state, action) => {
      state.errorCreate = action.payload;
      state.isLoadingCreate = false;
    },
  },
});

export const customerAttendUpdateSlice = createSlice({
  name: 'customerAttendUpdate',
  initialState,
  reducers: {
    startCustomerAttendUpdate: (state) => {
      state.isLoadingCustomerAttend = true;
    },
    customerAttendUpdateSuccess: (state, action) => {
      state.customerAttend = action.payload;
      state.isLoadingCustomerAttend = false;
    },
    customerAttendUpdateError: (state, action) => {
      state.errorCustomerAttend = action.payload;
      state.isLoadingCustomerAttend = false;
    },
    customerAttendUpdateReset: (state) => {
      state.customerAttend  = null;
    },
    // Arranging tables
    startArrangedTableUpdate: (state) => {
      state.isLoadingArrangedTable = true;
    },
    arrangedTableSuccess: (state, action) => {
      state.arrangedTable = action.payload;
      state.isLoadingArrangedTable = false;
    },
    ArrangedTableError: (state, action) => {
      state.errorArrangedTable = action.payload;
      state.isLoadingArrangedTable = false;
    },
    // Choosing table number
    startChangeTableNumber: (state) => {
      state.isLoadingChangeTableNumber = true;
    },
    changeTableNumberSuccess: (state, action) => {
      state.changeTableNumber = action.payload;
      state.isLoadingChangeTableNumber = false;
    },
    changeTableNumberError: (state, action) => {
      state.errorChangeTableNumber = action.payload;
      state.isLoadingChangeTableNumber = false;
    },
  },
});

export const getTutorialStepsSlice = createSlice({
  name: 'GetTutorialSteps',
  initialState,
  reducers: {
    startGetTutorialSteps: (state) => {
      state.isLoadingTutorialSteps= true;
    },
    getTutorialStepsSuccess: (state, action) => {
      state.tutorialSteps = action.payload;
      state.isLoadingTutorialSteps = false;
    },
    getTutorialStepsError: (state, action) => {
      state.tutorialStepsError = action.payload;
      state.isLoadingTutorialSteps = false;
    },
  },
});

export const createRestaurantSlice = createSlice({
  name: 'createRestaurant',
  initialState,
  reducers: {
    startCreateRestaurant: (state) => {
      state.isLoadingCreateRestaurant= true;
    },
    CreateRestaurantSuccess: (state, action) => {
      state.createRestaurant = action.payload;
      state.isLoadingCreateRestaurant = false;
    },
    CreateRestaurantError: (state, action) => {
      state.createRestaurantError = action.payload;
      state.isLoadingCreateRestaurant = false;
    },
    CreateRestaurantReset: (state) => {
      return {}
      },
  },
});

export const destroyItemSlice = createSlice({
  name: 'destroyItem',
  initialState,
  reducers: {
    startDestroyItem: (state) => {
      state.isLoadingDestroyItem= true;
    },
    destroyItemSuccess: (state, action) => {
      state.destroyItem = action.payload;
      state.isLoadingDestroyItem = false;
    },
    destroyItemError: (state, action) => {
      state.destroyItemError = action.payload;
      state.isLoadingDestroyItem = false;
    },
  },
});

export const restaurantNotificationsSlice = createSlice({
  name: 'restaurantNotifications',
  initialState,
  reducers: {
    restaurantNotificationsStart: (state) => {
      state.isLoadingRestaurantNotifications= true;
    },
    restaurantNotificationsSuccess: (state, action) => {
      state.restaurantNotifications = action.payload;
      state.isLoadingRestaurantNotifications = false;
    },
    restaurantNotificationsError: (state, action) => {
      state.restaurantNotificationsError = action.payload;
      state.isLoadingRestaurantNotifications = false;
    },
    // Create Notification
    createNotificationStart: (state) => {
      state.isLoadingCreateNotification= true;
    },
    createNotificationSuccess: (state, action) => {
      state.createNotification = action.payload;
      state.isLoadingCreateNotification = false;
    },
    createNotificationError: (state, action) => {
      state.createNotificationError = action.payload;
      state.isLoadingCreateNotification = false;
    },
    // Update Notification
    updateNotificationStart: (state) => {
      state.isLoadingUpdateNotification = true;
    },
    updateNotificationSuccess: (state, action) => {
      state.updateNotification = action.payload;
      state.isLoadingUpdateNotification = false;
    },
    updateNotificationError: (state, action) => {
      state.updateNotificationError = action.payload;
      state.isLoadingUpdateNotification = false;
    },
    // Confirm Notification
    confirmNotificationStart: (state) => {
      state.isLoadingConfirmNotifications= true;
    },
    confirmNotificationSuccess: (state, action) => {
      state.confirmNotifications = action.payload;
      state.isLoadingConfirmNotifications = false;
    },
    confirmNotificationError: (state, action) => {
      state.restaurantNotificationsError = action.payload;
      state.isLoadingConfirmNotifications = false;
    },
  },
});

export const restaurantCreatePaymentIntentSlice = createSlice({
  name: 'restaurantCreatePaymentIntent',
  initialState,
  reducers: {
    createPaymentIntentStart: (state) => {
      state.isLoadingPaymentIntent= true;
    },
    createPaymentIntentSuccess: (state, action) => {
      state.paymentIntent = action.payload;
      state.isLoadingPaymentIntent = false;
    },
    createPaymentIntentError: (state, action) => {
      state.paymentIntentError = action.payload;
      state.isLoadingPaymentIntent = false;
    },
    paymentIntentReset: (state) => {
      state.paymentIntent = false;
    },
    // Confirming payment
    confirmSubscriptionStart: (state) => {
      state.isLoadingConfirmSubscription= true;
    },
    confirmSubscriptionSuccess: (state, action) => {
      state.confirmSubscription = action.payload;
      state.isLoadingConfirmSubscription = false;
    },
    confirmSubscriptionError: (state, action) => {
      state.confirmPaymentError = action.payload;
      state.isLoadingConfirmSubscription = false;
    },
    confirmSubscriptionReset: (state) => {
      state.confirmSubscription = false;
    },
    // Get_subscription_packs
    getSubscriptionPackStart: (state) => {
      state.getSubscriptionPackIsLoading= true;
    },
    getSubscriptionPackSuccess: (state, action) => {
      state.getSubscriptionPack = action.payload;
      state.getSubscriptionPackIsLoading = false;
    },
    getSubscriptionPackError: (state, action) => {
      state.getSubscriptionError = action.payload;
      state.getSubscriptionPackIsLoading = false;
    },
    // Verify subscription
    veriySubscriptionStart: (state) => {
      state.veriySubscriptionIsLoading= true;
    },
    veriySubscriptionSuccess: (state, action) => {
      state.verifySubscription = action.payload;
      state.veriySubscriptionIsLoading = false;
    },
    veriySubscriptionError: (state, action) => {
      state.verifySubscriptionError = action.payload;
      state.veriySubscriptionIsLoading = false;
    },
  },
});

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    startCreatingFilters: (state) => {
      state.isLoadingCreateFilters = true;
    },
    createFiltersSuccess: (state, action) => {
      state.createdFilters = action.payload;
      state.isLoadingCreateFilters = false;
    },
    createFiltersError: (state, action) => {
      state.createdFiltersError = action.payload;
      state.isLoadingCreateFilters = false;
    },
    // fetching filter list
    startFetchingFilters: (state) => {
      state.isLoadingFetchingFilters = true;
    },
    fetchingFiltersSuccess: (state, action) => {
      state.fetchedFilters = action.payload;
      state.isLoadingFetchingFilters = false;
    },
    fetchingFiltersError: (state, action) => {
      state.fetchedFiltersError = action.payload;
      state.isLoadingFetchingFilters = false;
    },
    // delete filter
    startDeletingFilters: (state) => {
      state.isLoadingDeletingFilters = true;
    },
    deletingFiltersSuccess: (state, action) => {
      state.deletingFilters = action.payload;
      state.isLoadingDeletingFilters = false;
    },
    deletingFiltersError: (state, action) => {
      state.deletingFiltersError = action.payload;
      state.isLoadingDeletingFilters = false;
    },
    // delete filter
    startUpdatingFilters: (state) => {
      state.isLoadingUpdatingFilters = true;
    },
    updatingFiltersSuccess: (state, action) => {
      state.updatingFilters = action.payload;
      state.isLoadingUpdatingFilters = false;
    },
    updatingFiltersError: (state, action) => {
      state.updatingFiltersError = action.payload;
      state.isLoadingUpdatingFilters = false;
    },
  },
});


export default restaurantListSlice.reducer








