import React from 'react'
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faUserGroup, 
         faSun, faMap, faClock, faPlus, 
         faMinus, faMessage, faBabyCarriage, 
         faWheelchair, faFloppyDisk, faSquarePhoneFlip } from '@fortawesome/free-solid-svg-icons'
         
function StepThreeReservations({
        fullName, setFullName, email, setEmail, emailWS,
        StyledPhoneInput, countryCode, setCountryCodeNumber,
        InputOverlay, phoneWithoutCountryCode, handleInputPhoneChange,
        Extras, isExtrasOpen, handleExtras, ExtrasInside, babyChairs, DivFlexStart,
        setBabyChairs, DivFlexEnd, ScrollInputOptions, numBabyChairs, handleBabyChange,
        limitPeopleBaby, isMessageOpen, setIsMessageOpen, isAccessOpen, message,
        setMessage, maxMessageLength, CustomSelect, accessabilities, setIsAccessOpen,
        setAccessability, ActionButtons, goBack, accessability, setShowTermsAndConditions,
        handleSubmit, buttonsFalse, true_create_false_edit, handleEdit, phoneWS, fullNameWS, 
        acceptTerms, setAcceptTerms, acceptTermsWS,from_restaurant, creating
}) {
    console.log('inside tree: ', setEmail)
  return (
    <>
        <Row>
            <Col xs={12} >
                <Form.Label>Nome</Form.Label>
                <Form.Control 
                    type="text" 
                    value={fullName} 
                    onChange={(e) => setFullName(e.target.value)} 
                    onKeyDown={(e) => {
                        if (
                            (e.target.value.match(/ /g) || []).length > 1 && 
                            e.key === " " ||
                            e.target.value.length >= 20 && e.key !== "Backspace" && e.key !== "Delete"
                        ) {
                            e.preventDefault();
                        }
                    }}
                    maxLength={20}
                    required
                />
                {fullNameWS && <p style={ {color: "red", }}> Falta preencher nome </p>}
            </Col>
            <Col xs={12}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => {
                    if (!creating) return; // Prevent changing if not creating
                    console.log('Email field updated', e.target.value);
                    setEmail(e.target.value);
                    }}
                    readOnly={!creating} // Set readOnly if not creating
                    required
                />
                {emailWS && <p style={{ color: "red" }}> Falta preencher email </p>}
                </Col>

                <Col xs={12}>
                <div style={{ position: 'relative' }}>
                    <Form.Label>Telemóvel</Form.Label>
                    <StyledPhoneInput
                    country={countryCode}
                    onChange={(value) => {
                        if (!creating) return; // Prevent changing if not creating
                        setCountryCodeNumber(value);
                    }}
                    inputClass="form-control"
                    placeholder="Número de telemóvel"
                    inputProps={{
                        name: 'phone',
                        required: true,
                        readOnly: true, // Set readOnly if not creating
                        position: 'relative',
                    }}
                    />
                    <InputOverlay
                    maxLength={10}
                    value={phoneWithoutCountryCode}
                    onChange={(e) => {
                        if (!creating) return; // Prevent changing if not creating
                        handleInputPhoneChange(e);
                    }}
                    readOnly={!creating} // Set readOnly if not creating
                    />
                    <FontAwesomeIcon
                    size='xl'
                    icon={faSquarePhoneFlip}
                    style={{ color: "#48c778", position: 'absolute', top: '39px', left: '270px' }}
                    />
                </div>
                {phoneWS && <p style={{ color: "red" }}> Falta preencher numero de telemóvel </p>}
                </Col>
            <Col xs={12}>
            <Row>
                <Col xs={12}>
                <Extras isExtrasOpen={isExtrasOpen} onClick={handleExtras}>
                    Extras <FontAwesomeIcon size="xl" icon={ isExtrasOpen ? faMinus : faPlus} style={ isExtrasOpen ? {color: "#ffffff",} : {color: "#58d18c",}}/>
                </Extras>
                </Col>
                {isExtrasOpen && (
                    <>
                        <Col xs={12}>
                        <ExtrasInside status={babyChairs} onClick={() => setBabyChairs(!babyChairs)}>
                            <Row>
                            <Col xs={6}>
                                <DivFlexStart>
                                <FontAwesomeIcon size="xl" icon={faBabyCarriage} style={ babyChairs ? {color: "#ffffff", } : {color: "#58d18c", }}/> 
                                <h7 className='ms-3'>
                                    Cadeiras de bebé
                                </h7> 
                                </DivFlexStart>
                            </Col>
                            <Col xs={6}>
                                <DivFlexEnd>
                                <FontAwesomeIcon size="xl" icon={babyChairs ? faMinus : faPlus} style={ babyChairs ? {color: "#ffffff",} : {color: "#58d18c",}}/>
                                </DivFlexEnd>
                            </Col>
                            </Row>
                        </ExtrasInside> 
                        </Col>
                        {babyChairs &&
                        <Col xs={12} className='mt-3'>
                            <ScrollInputOptions currentOption={numBabyChairs} handleOptionsChange={handleBabyChange} options={limitPeopleBaby.baby_chair_numbers}/>
                        </Col>
                        }

                        
                        

                        <Col xs={12}>
                        <ExtrasInside status={isAccessOpen} onClick={() => setIsAccessOpen(!isAccessOpen)}>
                            <Row>
                            <Col xs={6}>
                                <DivFlexStart>
                                <FontAwesomeIcon size="xl" icon={faWheelchair} style={ isAccessOpen ? {color: "#ffffff", } : {color: "#58d18c", }}/> 
                                <h7 className='ms-3'>Acessibilidade</h7>
                                </DivFlexStart>
                            </Col>
                            <Col xs={6}>
                                <DivFlexEnd>
                                <FontAwesomeIcon size="xl" icon={isAccessOpen ? faMinus : faPlus} style={ isAccessOpen ? {color: "#ffffff",} : {color: "#58d18c",}}/>
                                </DivFlexEnd>
                            </Col>
                            
                            </Row>
                        </ExtrasInside> 
                        {isAccessOpen &&
                        <Col xs={12} className='mt-3'>
                            <CustomSelect
                                items={accessabilities}
                                onSelectChange={(value) => setAccessability(value)}
                                selectedItem={accessability}
                                setItem={setAccessability}
                            />
                        </Col>
                        }
                        </Col>
                        {creating &&
                        <Col xs={12}>
                        <ExtrasInside status={isMessageOpen} onClick={() => setIsMessageOpen(!isMessageOpen)}>
                            <Row>
                            <Col xs={6}>
                                <DivFlexStart>
                                <FontAwesomeIcon size="xl" icon={faMessage} style={ isMessageOpen ? {color: "#ffffff", } : {color: "#58d18c", }}/> 
                                <h7 className='ms-3'>
                                    Mensagem
                                </h7>
                                </DivFlexStart>
                            </Col>
                            <Col xs={6}>
                                <DivFlexEnd>
                                <FontAwesomeIcon size="xl" icon={isMessageOpen ? faMinus : faPlus} style={ isMessageOpen ? {color: "#ffffff",} : {color: "#58d18c",}}/>
                                </DivFlexEnd>
                            </Col>
                            
                            </Row>
                        </ExtrasInside>
                        {isMessageOpen &&
                        <Col xs={12} className='mt-3'>
                        <Form.Control 
                            as="textarea" 
                            rows={3} 
                            value={message} 
                            maxLength={maxMessageLength}
                            onChange={(e) => setMessage(e.target.value)} 
                            required
                        />
                        <div className="text-right">{message.length}/{maxMessageLength}</div>
                        </Col>
                        }
                        </Col>
                        
                        }
                        
                        
                        
                    </>
                )}
            </Row>
            </Col>
            <Col xs={12} className='mt-4'>
                {!buttonsFalse &&
                    <div className="terms-and-conditions">
                    <input className='mt-2' type="checkbox" checked={acceptTerms}  onClick={() => setAcceptTerms(!acceptTerms)}/> &nbsp;
                    <span>
                    Eu li e aceito os &nbsp;
                    <a onClick={() => setShowTermsAndConditions(true)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                        Termos e condições
                    </a>
                    .
                    </span>
                    {acceptTermsWS && <p style={ {color: "red", }}> Precisa de aceitar os termos e condições para continuar. </p>}
                    </div>
                    
                }
            
            </Col>
        </Row>
        <ActionButtons>
            {!buttonsFalse && 
                <Button className='mt-3' onClick={goBack}>voltar</Button>
            }
            { (true_create_false_edit && !from_restaurant) &&
            <Button className='mt-3' onClick={e => {
                if (true_create_false_edit) {
                    handleSubmit(e);
                    
                } else {
                    handleEdit(e);
                }
            }}
            > 
                Solicitar reserva
            </Button>
            } 
            
        </ActionButtons>
        
    </>
  )
}

export default StepThreeReservations