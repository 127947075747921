import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Card, Button, Row, Col, Modal, CardText } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getRestaurantSubscription } from '../actions/userActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUtensils, faEnvelope, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createRestaurant, fetchRestaurantDetails } from '../actions/restaurantActions';

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledCard = styled(Card)`
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  overflow: hidden;
`;

const CardTop = styled.div`
  padding: 10px;
  background-color: white;
  justify-content:center;
`;

const CardBottom = styled.div`
  padding: 10px;
  background-color: #8FEBC5;
`;

const StyledRow = styled(Row)`
  height: 100%;
  align-items: center;
  width: 100%;
  position:relative;
  margin-left:0;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify ? props.justify : "start"};
`;

const StyledColForIcons = styled(Col)`
  align-items: center;
  position:relative;
  justify-content:start;
`;

const Styledh6 = styled.h6`
  font-size: 1rem;
  margin-left:3px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Smallh6 = styled.h6`
  font-size: 0.8rem;
  margin: 0;
`;

const StyledIconText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px; 
  position:absolute;
  top:3px;
  left:2rem;
`;
const ModalCard = styled(Card)`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;
  margin-bottom: 10px;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 800px;
    width: 100%;
  }
  .modal-content {
    background-color: #F5F5F5;
  }
`;

const ModalHeader = styled(Modal.Header)`
  border-bottom: 1px solid black;
`;
const ModalTitle = styled(Modal.Title)`
  margin-left: 10px;
  color: ${props => props.selected ? "white" : "black"};
`;
const ModalTitleText = styled.span`
  color: ${props => props.selected ? "white" : "black"};
`;


const ModalCardText = styled(Card.Text)`
  color: ${props => props.selected ? "white" : "black"};
`;

function RestaurantsHeader() {
  const firstOption = ["Seguir tutorial",
  "Ao criar o restaurante com o tutorial ativado, explicamos como configurar todas as opções ao seu gosto."]
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(firstOption);
  const [showHeader, setShowHeader] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const restaurant_sub = useSelector((state) => state.restaurant_subscription);
  const { restaurantSubscription } = restaurant_sub;

  const manager_info = useSelector(state => state.crudManager)
  const { managerInfo } = manager_info

  useEffect(() => {
    if (restaurantSubscription && restaurantSubscription[0].account_type === 'not_vip') {
      setShowHeader(false);
      console.log('inside useeffect restaurant header: ', restaurantSubscription[0].account_type)
    } else {
      setShowHeader(true);
    }
  }, [restaurantSubscription]);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleSelect = (option) => {
    if (selectedOption === null || option[0] !== selectedOption[0]) {
      setSelectedOption(option);
    } else if (option[0] === selectedOption[0]) {
      setSelectedOption(null);
    }
};

  const handleCreateRestaurant = () => {
    console.log('selectedOption :', selectedOption)
    console.log('firstOption :', firstOption)
    dispatch(createRestaurant('reset', true));
    dispatch(fetchRestaurantDetails('reset', true));
    if (selectedOption[0] === firstOption[0]) {
      navigate(`/restaurant_creation/${'yes'}/${'null'}/${'null'}`);
    } else {
      navigate(`/restaurant_creation/${'no'}/${'null'}/${'null'}`);
    }
  };

  return (
    <StyledCard className="p-0">
      {managerInfo && showHeader? 
      <> 
      <CardTop>
        <StyledRow>
          <StyledCol xs={12} md={12}>
              Gerente convidado por {managerInfo.boss.name} 
          </StyledCol>
        </StyledRow>
      </CardTop>
      <CardBottom>
      </CardBottom>
        
      </>
      :
      <>
      {restaurantSubscription && showHeader?
      <>
      <CardTop>
        <StyledRow>
          <StyledCol xs={12} md={12}>
            { restaurantSubscription && restaurantSubscription[0].trial_days > 1 && 
              <Styledh6>Experimente já! {restaurantSubscription[0].trial_days} dias grátis no seu primeiro restaurante!!</Styledh6>
            }
          </StyledCol>
          <StyledCol xs={12} md={12}>
            <StyledRow>
              <StyledColForIcons xs={2} sm={2} md={1}>
                  <FontAwesomeIcon icon={faUtensils} />
                <StyledIconText>
                  <Styledh6>{restaurantSubscription[0].number_of_restaurants}</Styledh6>
                </StyledIconText>
              </StyledColForIcons>
              <StyledColForIcons xs={6} sm={2} md={1}>
                  <FontAwesomeIcon icon={faEnvelope}/>
                <StyledIconText>
                  <Styledh6>{restaurantSubscription[0].number_of_messages + restaurantSubscription[0].trial_messages}</Styledh6>
                </StyledIconText>
              </StyledColForIcons>
              <StyledCol xs={12} sm={8} md={10} justify="flex-end">
                <ActionButtons>
                  <Button variant="info" size="sm" className="mr-2" onClick={handleShow}> 
                    <Smallh6>Restaurante <FontAwesomeIcon size='lg' icon={faPlus} className="ml-2"/> </Smallh6>
                  </Button>
                </ActionButtons>
              </StyledCol>
            </StyledRow>
          </StyledCol>
        </StyledRow>
      </CardTop>
      <CardBottom>
      </CardBottom>
      </>
      :
      <>
      <CardTop>
        <StyledRow>
          <StyledCol xs={12} md={12}>
            Este projecto encontra-se em desenvolvimento, tentar mais tarde.
          </StyledCol>
        </StyledRow>
      </CardTop>
      <CardBottom>
      </CardBottom>
      </>
      }   
      </>
      }
      
      <StyledModal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title style={{marginLeft: '10px'}}>Selecionar uma opção</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {[["Seguir tutorial",
            "Ao criar o restaurante com o tutorial ativado, explicamos como configurar todas as opções ao seu gosto."], 
            ["Sem tutorial","Sem problema se tiver duvidas pode clicar no icon da lâmpada para ver as explicações."]].map((option, index) => (
              <Col md={6} onClick={() => handleSelect(option)} key={index}>
                <ModalCard className={selectedOption && option[0] === selectedOption[0] ? "bg-success" : ""}>
                  <ModalHeader>
                      <ModalTitle>
                        <ModalTitleText selected={selectedOption && option[0] === selectedOption[0]}>
                            {option[0]}
                        </ModalTitleText>
                      </ModalTitle>
                  </ModalHeader>

                  <Card.Body>
                      <ModalCardText selected={selectedOption && option[0] === selectedOption[0]}>
                          {option[1]}{option[1]}
                      </ModalCardText>
                      {selectedOption && option[0] === selectedOption[0] && (
                          <CheckIcon icon={faCheckCircle} className="text-white" />
                      )}
                  </Card.Body>
              </ModalCard>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleCreateRestaurant}>
            Criar
          </Button>
        </Modal.Footer>
      </StyledModal>
    </StyledCard>
  )
}

export default RestaurantsHeader;
