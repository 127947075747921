import React, { useState, useEffect, useRef  } from 'react';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { 
  fetchCancelReservation, 
  fetchReservationCustomerMenu, 
  fetchReservationActive,  
  getRestaurantTimeZone,
  postPhoneConfirmation,
  postResendPhoneConfirmation,
  reconfirmationByClientAction,
} from '../actions/reservationsActions';
import ReservationChat from '../components/ReservationChat';
import { fetchReservation, fetchRestaurantDetails, updateRestaurant, createRestaurant } from '../actions/restaurantActions';
import styled from 'styled-components';
import Loader from '../components/Loader';
import Messages from '../components/Messages';
import ReservationScreen from './ReservationScreen';
import { utcToZonedTime } from 'date-fns-tz';
import { formatDuration, intervalToDuration  } from 'date-fns';
import LoaderComponent from '../components/LoaderComponent'
import WaitCheckMark  from '../components/WaitCheckMark';
import { isValidNumber } from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css'; // Import the default CSS for react-phone-input-2
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'; // Import the Bootstrap CSS for additional styling
import {getDialCode, getCountryCode} from '../components/CountryCode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cancelledStatuses } from '../components/ReservationStatus';
import { 
  faSquarePhoneFlip, faArrowRotateLeft, faSpinner, faCheck, faExclamationTriangle, faPlus, faCalendar, 
  faFilter, faSun, faMap, faCircleDot, 
  faStopwatch, faChair, faUsers, faCircleXmark,
  faFilePen, faRectangleXmark, faEnvelope, 
  faWheelchairMove, faBaby, faBell, faReply,
  faSearch, faSquareCheck, faUser, faSquarePhone, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { GoogleMap, Marker, useLoadScript, useJsApiLoader  } from '@react-google-maps/api';
/* global google */



const Wrapper = styled(Container)`
  max-width: 800px;
  margin: 40px auto;
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h3`
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
`;

const Label = styled.span`
  font-weight: bold;
`;

const ActionButtons = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
`;

const NumberBox = styled.div`
  width: 50px;
  height: 50px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius:5px;
  margin: 5px;
  font-size:18px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  color:white;
  background-color:#1fa34f;
  &:hover {
    background-color: #25b359;
  }
`;

const StyledButton = styled(Button)`
  background-color: #32cd32;
  border: 2px solid #32cd32;
  font-size: 1rem;
  margin: 3px;
  &:hover {
    background-color: #00b300;
    border-color: #00b300;
    cursor: pointer;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    max-width: 100%;
    box-sizing: border-box;
    overflow:hidden;
  }
`;
const InputOverlay = styled.input`
  position: absolute;
  top: 2px;
  font-size:16px;
  left: 105px; 
  height: 36px;
  border: none;
  border-radius: 0 3px 0 0;
  background: none;
  outline: none;
  background-color:none;
  padding-bottom:4px;
`;

const AbsoluteKeySquare = styled.div`
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius: 4px 0 0 4px;
  background-color: #f2f2f2;
  border: solid 1px #cfcfcf;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 38px;
  padding: 1px;
  font-size: 18px;
  text-align: center;
  &:hover {
    background-color: #e8e8e8;
    border-color: #76d6c5;
  }
`;

function deepEqual(obj1, obj2) {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}


const ReservationCustomersMenuScreen = () => {
  const params = useParams()
  const [showModal, setShowModal] = useState(false);
  const [reservationCancelled, setReservationCancelled] = useState(false);
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
  const [isTimeExpired, setIsTimeExpired] = useState(false);
  const [timeLeft, setTimeLeft] = useState(false);
  const [restaurant_id, setRestaurant_id] = useState('');
  const [reservationObj, setReservationObj] = useState(false);
  const [totalDuration, setTotalDuration] = useState(null); // set this to your timer's total duration in seconds when it starts
  const [currentDuration, setCurrentDuration] = useState(totalDuration); // decrement this every second
  const [randomizedNumbers, setRandomizedNumbers] = useState([]);

  const [phoneWS, setPhoneWS] = useState(false);
  const [countryCodeNumber, setCountryCodeNumber] = useState('351');
  const [countryCode, setCountryCode] = useState("us");
  const [phoneWithoutCountryCode, setPhoneWithoutCountryCode] = useState('');

  const [email, setEmail] = useState('');
  const [emailWM, setEmailWM] = useState(false);

  const [longitudeField, setLongitudeField] = useState(false);
  const [latitudeField, setLatitudeField] = useState(false);

  const [chatModal, setChatModal] = useState(false);

  const [values, setValues] = useState(['', '']);
  const inputRefs = [useRef(null), useRef(null)];

  
  const [verifyCancelReservation, setVerifyCancelReservation] = useState(false);
  const [showModalReconfirmation, setShowModalReconfirmation] = useState(false);

  useEffect(() => {
    if (
        !verifyCancelReservation && 
        params.change_status === 'cancel' && 
        reservationObj && 
        !cancelledStatuses.includes(reservationObj.status) // Check if reservation.status is NOT in the array
      ) {
        setVerifyCancelReservation(true);
        setShowModal(true);
      } else if ( params.change_status === 'contact') {
        setChatModal(true);

      } else if ( params.change_status === 'confirmit' && 
        reservationObj && 
        !cancelledStatuses.includes(reservationObj.status) &&
        !reservationObj.reconfirmed_by_client
      ) {
        setShowModalReconfirmation(true);
      }
    console.log('i was hereeee senp')
  },[params])

  const { isLoaded: isGoogleLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA7wQgzKIx0-y6j3pfy341jX6LmYq5_Sc4"
  });

  const reservation_info = useSelector(state => state.reservationCustomersMenu)
  const {  reservation , isLoading, error, isCustomerLoader } = reservation_info

  const reservationBlockedWeekDays = useSelector((state) => state.reservationBlockedWeekDays);
  const { restaurantTimeZone } = reservationBlockedWeekDays;

  const createReservationStore = useSelector(state => state.createReservation)
  const { isPhoneConfirmationLoading, phoneConfirmation, phoneConfirmationError,
          isResendPhoneConfirmationLoading, resendPhoneConfirmation, resendPhoneConfirmationError } = createReservationStore

  const restaurantDetails = useSelector(state => state.restaurantDetails);
  const { restaurants} = restaurantDetails;

  const socket = useRef(null);

  const handleOnLoad = (map) => {
    // The google object is available here
    if (isGoogleLoaded) {
      google.maps.event.trigger(map, "resize");
    }
  };

  useEffect(() => {
    if (reservation){
      dispatch(fetchRestaurantDetails(reservation.restaurant));
    } 
  },[reservation, restaurant_id]);

  useEffect(() => {
    if (restaurants) {
      setLongitudeField(parseFloat(restaurants.longitude));
      setLatitudeField(parseFloat(restaurants.latitude))
      console.log('inside useffect: ', restaurants.longitude,  '   ', restaurants.latitude, restaurants.restaurant_name)
      console.log('inside useffect parse float: ', parseFloat(restaurants.longitude),  '   ', parseFloat(restaurants.latitude))
      console.log(restaurants)
    }
  },[restaurants]);

  const getRandomizedNumbers = (pinString, additionalNumber) => {
    // Split the pin string
    let numbers = pinString.split(';');

    // Add the additional number to the numbers list
    numbers.push(additionalNumber);

    // Randomize the numbers using sort and Math.random
    numbers.sort(() => Math.random() - 0.5);
  
    return numbers;
};

  // Assuming timeLeft is in the format 'mm:ss'
  const getTimeInSeconds = (time) => {
    if (typeof time !== 'string') {
        return 0; // Return a default value
    }

    const [minutes, seconds] = time.split(':');

    if (!minutes || !seconds) {
        return 0; // Return a default value if time isn't in "mm:ss" format
    }

    return parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
};

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams();



  const MAX_CHARACTER_LIMIT = 10; 
  const handleInputPhoneChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= MAX_CHARACTER_LIMIT) {
      setPhoneWithoutCountryCode(inputValue);
    }
  };
  
  useEffect(() => {
    // const timer = setInterval(() => {
    //   dispatch(fetchReservationCustomerMenu(id.token, id.change_status));
    // }, 10000);
    // return () => clearInterval(timer);
    dispatch(fetchReservationCustomerMenu(id.token, id.change_status));
  }, [id.token]);

  useEffect(() => {
    dispatch(fetchReservationCustomerMenu(id.token, id.change_status));
    dispatch(getRestaurantTimeZone(restaurant_id));
    dispatch(fetchReservationActive());
  }, [id.token, restaurant_id]);

  useEffect(() => {
    if (Object.keys(reservationObj).length !== 0) {
      let phoneWithoutCountryCoda;
      if (reservation && reservation.phone_number) {
        const countryCodeLength = countryCodeNumber.length;
        let phoneNumber = reservation.phone_number;
        // Remove leading plus if it exists
        if (phoneNumber[0] === '+') {
          phoneNumber = phoneNumber.slice(1);
        }
        // Remove leading country code number
        phoneWithoutCountryCoda = phoneNumber.slice(countryCodeLength);
      }
      setCountryCodeNumber(reservationObj.country_code)
      setPhoneWithoutCountryCode(phoneWithoutCountryCoda);
      setEmail(reservationObj.email)
    }

  }, [reservationObj]);

  useEffect(() => {
      setCountryCode(getCountryCode(countryCodeNumber));
  }, [countryCodeNumber]);

  useEffect(() => {
    if (reservationObj && reservationObj.confirmation_code_for_phone && !isResendPhoneConfirmationLoading) {

      const numbers = getRandomizedNumbers(reservationObj.confirmation_code_for_phone, reservationObj.confirmation_code_for_email.slice(0, 2));
      setRandomizedNumbers(numbers);
    }
  }, [reservationObj, isResendPhoneConfirmationLoading]);

  useEffect(() => {
    if (reservationObj && reservationObj.date_time_limit_for_confirmation && restaurantTimeZone && reservationObj.status !== 'Accepted') {
        const zonedDateNow = utcToZonedTime(new Date(), restaurantTimeZone.timezone);
        const zonedDateLimit = new Date(reservationObj.date_time_limit_for_confirmation);
        const startTimer = () => {
          const now = new Date();
          const diff = intervalToDuration({
              start: now,
              end: zonedDateLimit
          });
      
          // Get minutes and seconds from the duration
          let minutes = diff.minutes || 0;
          let seconds = diff.seconds || 0;
      
          // If there are hours in the duration, convert it to minutes
          if (diff.hours) {
              minutes += diff.hours * 60;
          }
      
          // Format minutes and seconds to "mm:ss"
          const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      
          // If the date_time_limit_for_confirmation has passed
          if (zonedDateNow > zonedDateLimit) {
              clearInterval(interval);
              setTimeLeft(null);
              setIsTimeExpired(true);
          } else {
              setTimeLeft(formattedTime);
          }
      };

        const interval = setInterval(startTimer, 1000);
        startTimer();

        // Clear the interval when the component is unmounted
        return () => clearInterval(interval);
    }
}, [reservationObj, restaurantTimeZone]);

useEffect(() => {
      if (timeLeft && typeof timeLeft === 'string' && timeLeft.includes(':')) {
        setCurrentDuration(getTimeInSeconds(timeLeft));
        if (totalDuration === null) {
            setTotalDuration(getTimeInSeconds('05:00'));
        }
    }
},[totalDuration, currentDuration, timeLeft]);

  useEffect(() => {
    if (reservation) {
      if (restaurant_id != reservation.restaurant) {
        setRestaurant_id(reservation.restaurant);
      }
      if (!deepEqual(reservation, reservationObj)) {
        setReservationObj(reservation);
      }
    }
  }, [reservation]);

  const handleEdit = () => {
   navigate()
  };

  const handleCancel = async () => {
    setShowModal(false);
    setShowModalReconfirmation(false);
    try {
      const data = { 'isCustomer': true, 'token':id.token }
      // Replace with your API endpoint to cancel the reservation
      const response = await axios.post(`/api/reservations/cancel_reservation/`, data);
      if (response.data.success) {
      }
    } catch (error) {
      console.error('Error cancelling reservation:', error);
    }
    setReservationCancelled(true)
    dispatch(fetchReservationCustomerMenu(id.token, true));
  };

  const handleReconfirmation= async () => {
    setShowModalReconfirmation(false);
    dispatch(reconfirmationByClientAction(id.token));
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseReservationModal = () => {
    setIsReservationModalOpen(false);
  };
   
  const handleOpenEditReservationModal = () => {
    setIsReservationModalOpen(true)
  };
  useEffect(() => {
    const circle = document.getElementById('timerCircle');
    if (!circle) return;

    const circumference = 2 * Math.PI * 45;
    circle.style.strokeDasharray = `${circumference}`;

    const updateCircle = () => {
        const percentageElapsed = 1 - (currentDuration / totalDuration);
        const offset = -percentageElapsed  * circumference;

        circle.style.strokeDashoffset = offset;
    };
    updateCircle();
}, [currentDuration, totalDuration, reservationObj]);

  const handleNumberClick = () => {
    // Join the values from both input boxes
    const joinedNumber = values.join('');
    
    // Dispatch action with the joined number
    dispatch(postPhoneConfirmation(reservationObj.token, joinedNumber));
  };
  
  // Email validation function using regex
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleResendPhoneCode = () => {
    if (validateEmail(email)) {
      setEmailWM(false);
      dispatch(postResendPhoneConfirmation(reservationObj.token, countryCodeNumber, countryCodeNumber + phoneWithoutCountryCode, email));
    } else {
      setEmailWM(true); // Set warning if email is invalid
    }
    
  };

  function openGoogleMapsDirections() {
    // Replace these with your actual longitude and latitude values
    var latitude = latitudeField;
    var longitude = longitudeField;

    // Construct the Google Maps Directions URL
    var googleMapsDirectionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;

    // Open the URL in a new window/tab or in the Google Maps app on mobile devices
    window.open(googleMapsDirectionsUrl, '_blank');
}

  useEffect(() => {
    console.log('latitudeField: ', latitudeField)
    console.log('longitudeField: ', longitudeField)
  },[latitudeField, longitudeField])

  // This function is called when a new message is received from the WebSocket
  const handleReservationUpdate = (newReservation) => {
    setReservationObj(newReservation);
  };

useEffect(() => {
  if (id) {
    const websocketBaseURL = process.env.REACT_APP_WEBSOCKET_URL;
    socket.current = new WebSocket(`${websocketBaseURL}reservation/${id.token}/`);
  
    socket.current.onmessage = (event) => {
      try {
          const data = JSON.parse(event.data);
  
          // Check for chat history type message
          if (data.type === 'reservation_update') {
              console.log('Reservation data:', data.reservation);
              console.log('Updating reservation...');
              handleReservationUpdate(data.reservation);
          } else {
              console.log('Received a different type of message');
          }
      } catch (error) {
          console.error('Error parsing JSON:', error);
      }
  };
    
    socket.current.onopen = () => {
        console.log('WebSocket Connected');
    };
  
    socket.current.onclose = () => {
        console.log('WebSocket Disconnected');
    };
  
    return () => socket.current?.close();

  }
    
}, [id]);

  // Handle changes in the input boxes
  const handleChange = (e, idx) => {
    const newValue = e.target.value;

    if (newValue.length <= 1 && !isNaN(newValue)) { // Only allow single digit
      const newValues = [...values];
      newValues[idx] = newValue;
      setValues(newValues);

      // Move to the next box if current one is filled
      if (newValue !== '' && idx < inputRefs.length - 1) {
        inputRefs[idx + 1].current.focus();
      }
    }
  };

  // Handle backspace in the second input box to move focus to the first
  const handleKeyDown = (e, idx) => {
    if (e.key === 'Backspace' && values[idx] === '' && idx > 0) {
      inputRefs[idx - 1].current.focus();
    }
  };


  return (
    <>
    <LoaderComponent
      activate={isPhoneConfirmationLoading || isResendPhoneConfirmationLoading || isCustomerLoader}
      theSize={'o'}
    />
    {Object.keys(reservationObj).length !== 0 && reservationObj.is_phone_confirmed === true || reservationObj.status !== 'Pending_from_customer'?
    <Wrapper>
      <Title>Reserva</Title>
      <Row>
        <Col xs={12} sm={12} md={6}>
          <p>
            <FontAwesomeIcon icon={faUser} style={{color: "#49ca9f",marginRight:'5px'}} /> {reservationObj.full_name}
          </p>
          <p>
          <FontAwesomeIcon icon={faEnvelope} style={{color: "#49ca9f",marginRight:'5px'}}/> {reservationObj.email}
          </p>
          <p>
            <FontAwesomeIcon icon={faSquarePhone} style={{color: "#49ca9f",marginRight:'5px'}}/> {'+' + countryCodeNumber + ' ' + phoneWithoutCountryCode}
          </p>
          <p>
          <FontAwesomeIcon icon={faCalendar} style={{color: "#49ca9f",marginRight:'5px'}}/> {reservationObj.date}
          </p>
          <p>
          <FontAwesomeIcon icon={faStopwatch} style={{color: "#49ca9f",marginRight:'5px'}}/>{reservationObj.time}
          </p>
          <p>
          <FontAwesomeIcon icon={faChair} style={{color: "#49ca9f",marginRight:'5px'}}/> {reservationObj.number_of_people}
          </p>
          {reservationObj.baby_chair > 0 &&
            <p>
              <FontAwesomeIcon icon={faBaby} style={{color: "#49ca9f",marginRight:'5px'}}/> {reservationObj.baby_chair}
            </p>
          }
          { reservationObj.message !== '' && reservationObj.message !== null> 0 &&
            <p>
              <FontAwesomeIcon icon={faEnvelope} style={{color: "#49ca9f",marginRight:'5px'}}/> {reservationObj.message}
            </p>
          }
          {reservation && !cancelledStatuses.includes(reservationObj.status) &&
            <p>
              <ReservationChat reservation={reservationObj} openModal={chatModal} setChatModalTrueOrFalse={setChatModal} isClient={true}/>
            </p>
          }
          
          
          <p>
            <Label>Estado:</Label> {reservationObj.status == 'Pending_from_customer'?
                                      <h5>
                                        <Messages variant='warning' ><p style={{color:'black'}}>Favor confirmar o seu pedido no SMS enviado.</p></Messages>
                                        <p style={{fontSize: '10px', marginLeft:'5px'}}>Caso não receba o sms nos proximos minutos, verifique se o seu numero está correcto.</p>
                                      </h5>:
                                    reservationObj.status == 'Accepted' ||
                                    reservationObj.status == 'Rejected_edition' ||
                                    reservationObj.status == 'Accepted_edit' ||
                                    reservationObj.status == 'Attended' ||
                                    reservationObj.status == 'Accepted_Without_Table' ||
                                    reservationObj.status == 'Accepted_With_Table'?
                                      <h5><Messages variant='success'>Reserva confirmada</Messages></h5>: 
                                    reservationObj.status == 'Cancelled' || 
                                    reservationObj.status == 'Cancelled_By_Restaurant' ||
                                    reservationObj.status == 'Cancelled_By_Customer' ||
                                    reservationObj.status == 'Cancelled_By_Customer_Warning' ||
                                    reservationObj.status == 'Cancelled_By_Expired_Code'  ?
                                      <h5><Messages variant='danger'>Reserva Cancelada.</Messages></h5>:
                                      <h5>
                                        <Messages variant='warning'>
                                          <p  style={{color:'black'}}>- A reserva não está confirmada. </p>
                                          <p  style={{color:'black'}}>- Aguarde EMAIL de confirmação do restaurante por favor.</p>
                                        </Messages>
                                      </h5>
                                    }
          </p>
        </Col>

        {isGoogleLoaded &&
          <Col xs={12} sm={12} md={6}>
          <a href={`tel:${'+' + restaurants.country_code + restaurants.phone_number}`} style={{
              display: 'inline-block', // Allows us to set width and height
              cursor: "pointer", 
              color: 'white', 
              backgroundColor: '#35c492', // Use your brand color
              height: '35px',
              lineHeight: '35px', // Vertically centers the text
              width: '100%',
              borderRadius: '5px',
              textAlign: 'center', // Centers the text horizontally
              textDecoration: 'none', // Removes the underline from the link
              fontSize: '16px', // Sets the font size
              fontWeight: 'bold', // Makes the text bold
              margin: '10px 0', // Adds some space around the button
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Adds a subtle shadow for depth
              }}>
              Contactar Restaurante <FontAwesomeIcon size='lg' icon={faPhone} style={{marginLeft:'7px'}}/>
          </a>
            
            <div 
              onClick={openGoogleMapsDirections}
              style={{
              width:'100%', 
              height:'225px', 
              borderRadius:'7px', 
              border:'1px solid gray', 
              overflow:'hidden',
              cursor:'pointer',
              position: 'relative',
              }}>
              <div style={{
                          width:'100%', 
                          height:'35px', 
                          backgroundColor:'#35c492', 
                          color:'white',  
                          display:'flex', 
                          justifyContent:'center',
                          alignItems: 'center',
                          fontSize:'20px',
                          fontWeight: 'bold',
                          }}>
                Abrir no google maps
              </div>
              <div style={{width:'100%', height:'190px'}}>
              <GoogleMap
              mapContainerClassName="map-container-customer-view"
              onLoad={handleOnLoad}
              center={{ lat: latitudeField, lng: longitudeField }}
              zoom={17}
              options={{
                streetViewControl: false, // Already disabling Street View
                mapTypeControl: false, // Disables the map/satellite option
                fullscreenControl: false, // Disables the fullscreen option
                zoomControl: false, // Disables zoom in/out controls
                gestureHandling: 'none', // Optional: Disables map panning or zooming via keyboard/mouse gestures
              }}
              >
                <Marker 
                  position={{ lat: latitudeField, lng: longitudeField }}
                />
              </GoogleMap>
              <div  style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer', // This makes the cursor a pointer
              }} />
            </div>
            </div>
          </Col>
        }
      </Row>
      { cancelledStatuses.includes(reservationObj.status) ? <div></div>: 
        <ActionButtons>
            <Button variant="primary"  onClick={handleOpenEditReservationModal} disabled={reservationObj.status === 'cancelled'}>
             Editar <FontAwesomeIcon icon={faFilePen} style={{color: "#ffffff",marginLeft:'7px'}} />
            </Button>
            
          <Button variant="danger" onClick={handleShowModal} disabled={reservationObj.status === 'cancelled'}>
            Cancelar Reserva <FontAwesomeIcon icon={faCircleXmark} style={{color: "#ffffff",marginLeft:'7px'}} />
          </Button>
        </ActionButtons>
      }
        {restaurants && 
          <div style={{ marginTop: '20px' }}>
            <div style={{ fontSize: '40px', display: 'flex', alignItems: 'center' }}>
              {restaurants.restaurant_name}
              <span style={{ fontSize: '20px', marginLeft: '10px' }}>{'(descrição)'}</span>
            </div>
            <div style={{ whiteSpace: 'pre-line', marginTop: '10px' }}>
              {restaurants.restaurant_description}
            </div>
          </div>
        }
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cancelar reserva</Modal.Title>
        </Modal.Header>
        <Modal.Body>De certeza que deseja cancelar a sua reserva?</Modal.Body>
        <Modal.Footer>
        <Button variant="success" onClick={handleCancel}>
            Sim
          </Button>
          <Button variant="danger" onClick={handleCloseModal}>
            Não
          </Button>
          
        </Modal.Footer>
      </Modal>

      <Modal show={showModalReconfirmation} onHide={() => setShowModalReconfirmation(!showModalReconfirmation)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirma a sua presença?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
        <Button variant="success" onClick={handleReconfirmation}>
            Confirmar
          </Button>
          <Button variant="danger" onClick={() => setShowModal(true)}>
            Não vou comparecer
          </Button>
          
        </Modal.Footer>
      </Modal>

      <ReservationScreen
        from_restaurant={false}
        creating={false}
        setCreating={false}
        isEditModalOpen={isReservationModalOpen}
        setIsEditModalOpen={setIsReservationModalOpen}
        restaurantId={restaurant_id}
        handleCloseReservationModal={handleCloseReservationModal}
        token_from_restaurant={id.token}
      />
    </Wrapper>
    : reservationObj.is_phone_confirmed === false && !isTimeExpired && !reservationObj.failed_to_confirm?
    <Wrapper>
      <Row>
        <Col xs={12} style={{display:'flex', justifyContent:'center'}}>
        <svg width="100" height="100" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="45" fill="none" stroke="#d3d3d3" strokeWidth="2" strokeDasharray="5 5" />
            <circle id="timerCircle" cx="50" cy="50" r="45" fill="none" stroke="#00ff00" strokeWidth="3" transform="rotate(-90 50 50)" />
            <text x="50" y="50" text-anchor="middle" dominant-baseline="middle">{timeLeft}</text>
        </svg>
        </Col>
        <Col xs={12} style={{textAlign:'center'}}>
          <Label>Enviamos-lhe {reservationObj.hasWhatsapp ? <>o código de confirmação para o seu<div style={{fontSize:'18px', color:'#24c64d'}}>Email ou Whatsapp</div></> : 'Email'}</Label>
          <p className='mt-3'>{reservationObj.hasWhatsapp && <FontAwesomeIcon size='xl' icon={faWhatsapp} style={{color: "#24c64d",}} />}{' +' + countryCodeNumber + ' ' + phoneWithoutCountryCode}</p>
          <p className='mt-3'>{reservationObj.email && <FontAwesomeIcon size='xl' icon={faEnvelope} style={{color: "#24c64d",}} />} {' '} {reservationObj.email}</p>

          <p>Inserir código de confirmação</p>
        
        </Col>
              <Col xs={12} style={{textAlign:'center'}}>
              <div className="numberGrid">
        {/* {randomizedNumbers.map((number, idx) => (
          <NumberBox 
            key={idx}
            onClick={() => handleNumberClick(number)}
          >
            {number}
          </NumberBox>
        ))} */}
        <div style={{ display: 'flex', gap: '10px', justifyContent:'center'}}>
            {values.map((value, idx) => (
            <input
              key={idx}
              ref={inputRefs[idx]}
              type="text"
              value={value}
              onChange={(e) => handleChange(e, idx)}
              onKeyDown={(e) => handleKeyDown(e, idx)}
              maxLength="1"
              style={{
                width: '40px',
                height: '50px',
                textAlign: 'center',
                fontSize: '1.5em',
                border: '2px solid #ccc',
                borderRadius: '8px',
                outline: 'none',
              }}
            />
          ))}
        </div>
      </div>
        </Col>
        <Col xs={12} style={{textAlign:'center'}}>
            <StyledButton onClick={handleNumberClick} style={{marginTop:'20px'}}>
              Confirmar <FontAwesomeIcon icon={faCheck} style={{color: "#ffffff",}} />
            </StyledButton>
        </Col>
      </Row>
      
              
        
        
    </Wrapper>
    : reservationObj.failed_to_confirm  && !isTimeExpired?
    <Wrapper>
      <Row className="d-flex justify-content-center">
      <Col xs={12} style={{display:'flex', justifyContent:'center'}}>
      <svg width="100" height="100" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="45" fill="none" stroke="#d3d3d3" strokeWidth="2" strokeDasharray="5 5" />
          <circle id="timerCircle" cx="50" cy="50" r="45" fill="none" stroke="#00ff00" strokeWidth="3" transform="rotate(-90 50 50)" />
          <line x1="25" y1="25" x2="75" y2="75" stroke="gray" strokeWidth="2" />
          <line x1="75" y1="25" x2="25" y2="75" stroke="gray" strokeWidth="2" />
      </svg>
      </Col>
      <Col xs={12} style={{textAlign:'center'}}>
      <p>
            <Label>Código errado, favor verificar numero de Telemóvel e Email.</Label>
      </p>
      </Col>
      <Col xs={12} style={{display:'flex', justifyContent:'center'}}>
            <div style={{ position: 'relative'}}>
                
                <StyledPhoneInput
                country={countryCode}
                onChange={setCountryCodeNumber}
                inputClass="form-control"
                placeholder="Número de telemóvel"
                inputProps={{
                name: 'phone',
                required: true,
                readOnly: true, // make the input field read-only
                position:'relative',
                }}
                > 
                </StyledPhoneInput>
                <InputOverlay maxlength={10} value={phoneWithoutCountryCode} onChange={handleInputPhoneChange}/>
                
                <FontAwesomeIcon size='xl' icon={faSquarePhoneFlip} style={{color: "#48c778", position: 'absolute', top: '8px', left: '270px'}} />
                
            </div>
            
      </Col>
      <p></p>
      <Col xs={6}>
          <Form>
                <Form.Group className="mb-3" controlId="formEmail">
                    <div style={{ position: 'relative' }}>
                        <Form.Control required type="email" value={email} onChange={(e) => setEmail(e.target.value)} style={{paddingLeft:'59px'}}/>
                        <AbsoluteKeySquare>
                            <FontAwesomeIcon size='lg' icon={faEnvelope} style={{color: "#78d9b8",}} />
                        </AbsoluteKeySquare>
                    </div>
                    {emailWM && <p style={{color:'red'}}>Favor preencher Email</p>}
                </Form.Group>
          </Form>
      </Col>
      <Col xs={12} style={{textAlign:'center'}}>
            <StyledButton onClick={handleResendPhoneCode} style={{marginTop:'20px'}}>
              Reenviar Código <FontAwesomeIcon icon={faSpinner} style={{color: "#ffffff", marginLeft:'10px'}} />
            </StyledButton>
            {phoneWS && <p style={ {color: "red", }}> numero ínvalido </p>}
            
            
      </Col>
      

      </Row>
      
        
        
    </Wrapper>
    : isTimeExpired ?
    <Wrapper>
      <Row>
      <Col xs={12} style={{display:'flex', justifyContent:'center'}}>
      <svg width="100" height="100" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="45" fill="none" stroke="#d3d3d3" strokeWidth="2" strokeDasharray="5 5" />
          <line x1="25" y1="25" x2="75" y2="75" stroke="red" strokeWidth="2" />
          <line x1="75" y1="25" x2="25" y2="75" stroke="red" strokeWidth="2" />
      </svg>
      </Col>
      <Col xs={12} style={{textAlign:'center'}}>
      <br></br>
      <p>
            <Label>O tempo expirou.</Label> 
            <br></br>
            <br></br>
            <Link to={`/reservations/${restaurant_id}/client/no_id`} style={{ textDecoration: 'none' }}>
              <StyledButton className="mx-2">
              Voltar a reservar <FontAwesomeIcon icon={faArrowRotateLeft} style={{color: "#ffffff",marginLeft:'8px'}} />
              </StyledButton>
            </Link>
        </p>
      </Col>
      </Row>
       
    </Wrapper>
    :
      <Loader/>
    }
    
    </>
    
  );
};

export default ReservationCustomersMenuScreen;

